<template>
    <div style="height:100vh;">
        <iframe v-if="flag" width="100%" height="100%" scrolling='no' :src='src' frameborder="0"></iframe>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                k:null,
                company_id:null,
                id:null,
                src:'',
                flag:false
            }
        },
        created() {
            
            this.k = this.$route.query.k
             this.company_id = this.$route.query.company_id
              this.id = this.$route.query.id
              let k,company_id,id
              if(this.k&&this.company_id&&this.id){
                  this.flag = true
                  this.src = `http://localhost:8080/#/supplierCrm/information?k=${this.k}&company_id=${this.company_id}&id=${this.id}`

              }
              console.log(this.src)
        },
    }
</script>

<style lang="scss" scoped>
.box{
    width: 100%;
    // height: 100%;
}
</style>